/**
 * Created by Max Gornostayev on 05/01/22
 *
 * path: /login
 * id: authSigninPage
 *
 * this is a page for signin form
 *
 */

import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom';
import BottomLinks from '../../components/theme/BottomLinks';
import Button from '../../components/buttons/Button';
import pageUrl from '../../const/pages';
import Alert from '../../components/popups/Alert';
import Loading from '../../components/elements/Loading';
import Validation from '../../lib/Validation';
import InputText from '../../components/forms/InputText';
import Header from '../../components/theme/Header';
import Select from '../../components/forms/Select';
import Utils from '../../lib/Utils';
import ButtonLink from '../../components/buttons/ButtonLink';
import countries from '../../const/countries';
import trans from '../../trans';
import '../../styles/auth.scss';

const SigninPage = observer(({ ContractsStore, UserStore }) => {
    //get prop 'tokenProp' from another screen
    const { state } = useLocation();
    const stateEmail = state && state.hasOwnProperty('email') ? state.email : '';
    let statePhone = state && state.hasOwnProperty('phone') ? state.phone : '';
    const isCompany = state && state.hasOwnProperty('isCompany') ? state.isCompany : !!stateEmail;

    if (statePhone.indexOf('00') === 0 && statePhone.length > 4) {
        statePhone = statePhone.substring(4);
    }

    //state variables
    const [isLoading, setIsLoading] = useState(false);
    const [phoneCode, setPhoneCode] = useState(countries.deutschland.code);
    const [phoneNumber, setPhoneNumber] = useState(statePhone);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState(stateEmail);
    // const [phoneNumber, setPhoneNumber] = useState('11764535556');
    // const [password, setPassword] = useState('12345qwert');
    // const [phoneNumber, setPhoneNumber] = useState('123887777777');

    //ref variables
    const refPhoneCode = useRef();
    const refPhoneNumber = useRef();
    const refAlert = useRef();
    const refPassword = useRef();
    const refEmail = useRef();

    //navigate variable that is used to go to another screen
    const navigate = useNavigate();

    const openForgotPasswordPage = () => {
        //go to forgot password
        navigate(pageUrl.authForgotEmailSend);
    };

    //button handler
    const next = async () => {
        if (isCompany) {
            const validObj = refEmail.current.validate();
            if (!validObj.isValid) {
                refAlert.current.error(validObj.validateMsg);
                return false;
            }
        } else {
            const validObj = refPhoneNumber.current.validate();
            if (!validObj.isValid) {
                refAlert.current.error(validObj.validateMsg);
                return false;
            }
        }
        setIsLoading(true);
        let res = {};
        if (!isCompany) {
            res = await UserStore.doLoginWithPhone({ phoneCode, phoneNumber, password });
        } else {
            res = await UserStore.doLoginWithEmail({ email, password });
        }
        setIsLoading(false);
        if (!res) {
            return false;
        }
        if (!res.status) {
            refAlert.current.error(res.msg);
            return false;
        }

        await ContractsStore.loadStore();
        navigate(pageUrl.secureDashboard);
    };

    //main rendering
    return (
        <div className="app signin-page">
            <Header>
                <h1>{trans.t('signinPage', 'h1')}</h1>
            </Header>
            <div className="content">
                <div className="wrapper">
                    <div className="wrapper-vsmall">
                        {!isCompany && (
                            <Select
                                ref={refPhoneCode}
                                title={trans.t('signinPage', 'phoneCode')}
                                options={Utils.getPhoneCodeList()}
                                onChange={(val) => setPhoneCode(val)}
                                defaultValue={phoneCode}
                            />
                        )}
                        {!isCompany && (
                            <InputText
                                ref={refPhoneNumber}
                                role="phoneNumberTestId"
                                title={trans.t('signinPage', 'phoneNumber')}
                                defaultValue={statePhone}
                                onChange={(val) => setPhoneNumber(val)}
                                className="margin-top-16"
                                validateFunc={(val) => Validation.phone(val, phoneCode)}
                            />
                        )}
                        {isCompany && (
                            <InputText
                                ref={refEmail}
                                role="emailTestId"
                                title={trans.t('signinPage', 'email')}
                                defaultValue={stateEmail}
                                onChange={(val) => setEmail(val)}
                                className="margin-top-16"
                                validateFunc={(val) => Validation.email(val)}
                            />
                        )}
                        <InputText
                            ref={refPassword}
                            role="passwordTestId"
                            type="password"
                            title={trans.t('signinPage', 'password')}
                            defaultValue={password}
                            className="margin-top-16"
                            onChange={(val) => setPassword(val)}
                        />
                        <Button role="btnNext" text={trans.t('signinPage', 'btnNext')} onClick={next} isRightIcon isFullWidth className="margin-top-48" />
                        <ButtonLink text={trans.t('signinPage', 'buttomLink')} onClick={openForgotPasswordPage} isTouch />
                    </div>
                </div>
            </div>
            <BottomLinks isBgBlue />
            <Alert ref={refAlert} />
            <Loading isShow={isLoading} />
        </div>
    );
});

export default SigninPage;
